<script>
import FormHandler from "~/mixins/formHandler";
import Rental from "~/modules/ask/services/Rental";

export default {
  name: "CancelEdit",
  emits: ['change'],
  props: {
    userType: {
      type: String,
      default: ''
    },
    ask: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data () {
    return {
      now: this.$moment(),
      nowInterval: null,
      reason: '0',
      edit: {
        reason: '0',
        otherReason: '',
        message: ''
      }
    }
  },
  computed: {
    getNowFormatted () {
      return this.now.format('DD/MM/YYYY HH:mm')
    },
    timeAfter3hourForPaidAt () {
      if (this.ask && this.ask.paidAtUnix) {
        const paidAt = this.$moment(this.ask.paidAtUnix, 'X')
        return paidAt.add(3, 'hours').format('HH:mm').replace(':', 'h')
      } else {
        return false
      }

    },
    isInTerms () {
      if (this.userType === 'owner') {
        false
      } else {
        return this.paymentLessThan3Hours
      }
    },
    nbDaysBeforeDeparture () {
      if (this.ask && this.ask.departUnix) {
        return this.$moment(this.ask.departUnix, 'X').diff(this.now, 'days', true).toFixed(2)
      } else {
        return false
      }
    },
    termDetails () {
      if (this.isInTerms) {
        if (this.ask && this.ask.typeAnnulation) {
          const nbDaysBeforeDeparture = this.$moment(this.ask.departUnix, 'X').diff(this.now, 'days', true).toFixed(2)
          const tenantTotalCost = this.ask.prix_total_ttc - this.ask.assurance_ht - this.ask.frais_service_ttc
          if (this.ask.typeAnnulation.id === 1) {
            if (nbDaysBeforeDeparture >= 2) {
              return {
                penaltyPercent: 0,
                penaltyAmount: 0
              }
            } else if (nbDaysBeforeDeparture > 0 && nbDaysBeforeDeparture < 2) {
              return {
                penaltyPercent: 25,
                penaltyAmount: tenantTotalCost * 0.25
              }
            } else if (nbDaysBeforeDeparture < 0) {
              return {
                penaltyPercent: 50,
                penaltyAmount: tenantTotalCost * 0.50
              }
            }
          } else if (this.ask.typeAnnulation.id === 2) {
            if (nbDaysBeforeDeparture >= 3) {
              return {
                penaltyPercent: 0,
                penaltyAmount: 0
              }
            } else if (nbDaysBeforeDeparture > 1 && nbDaysBeforeDeparture < 3) {
              return {
                penaltyPercent: 25,
                penaltyAmount: tenantTotalCost * 0.25
              }
            } else if (nbDaysBeforeDeparture >= 0 && nbDaysBeforeDeparture <= 1) {
              return {
                penaltyPercent: 50,
                penaltyAmount: tenantTotalCost * 0.50,
                penaltyFees: 0,
              }
            } else {
              return {
                penaltyPercent: 100,
                penaltyAmount: tenantTotalCost
              }
            }
          } else if (this.ask.typeAnnulation.id === 3) {
            if (nbDaysBeforeDeparture >= 10) {
              return {
                penaltyPercent: 0,
                penaltyAmount: 0
              }
            } else if (nbDaysBeforeDeparture >= 5 && nbDaysBeforeDeparture < 10) {
              return {
                penaltyPercent: 25,
                penaltyAmount: tenantTotalCost * 0.25
              }
            } else {
              return {
                penaltyPercent: 100,
                penaltyAmount: tenantTotalCost
              }
            }
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    paymentLessThan3Hours () {
      //
      if (this.ask && this.ask.paidAtUnix) {
        const paidAt = this.$moment(this.ask.paidAtUnix, 'X')
        const now = this.$moment()
        const diff = now.diff(paidAt, 'hours')
        return diff > 3
      }
      return false

    },
    username () {
      if (this.ask && this.ask.locataire && this.ask.locataire.prenom) {
        return this.ask.locataire.prenom
      } else {
        return ''
      }
    }
  },
  methods: {
    change () {
      console.log('change')
      this.$emit('change', this.edit)
    },
    formatPrice (price) {
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })
      return formatter.format(price)
    },
    updateMomentNow () {
      this.now = this.$moment()
    }
  },
  mounted () {
    this.now = this.$moment()
    this.nowInterval = setInterval(this.updateMomentNow, 1000)
  },
  unmounted () {
    clearInterval(this.nowInterval)
  }
}
</script>

<template>
  <el-form label-position="top" size="large">
    <div v-if="userType === 'owner'">
      <el-radio-group v-model="edit.reason" class="vertical-radio-list" @change="change">
        <el-radio label="0" size="large" class="responsive-radio">Votre matériel n’est pas disponible sur cette période</el-radio>
        <el-radio label="1" size="large">Vous n’êtes pas disponible sur cette période</el-radio>
        <el-radio label="2" size="large">Autre raison</el-radio>
      </el-radio-group>
      <el-form-item label="Raison" v-if="edit.reason == 2">
        <el-input v-model="edit.otherReason" placeholder="Raison" @change="change"></el-input>
      </el-form-item>
      <el-form-item :label="'Laissez un message :'">
        <el-input v-model="edit.message" type="textarea" placeholder="Message" @change="change"></el-input>
      </el-form-item>
    </div>
    <div v-if="userType === 'tenant'">
      <div v-if="ask && isInTerms">
        <p>
          {{ getNowFormatted}}
          {{$t('modals.cancel.loc.typeCancel', {type: ask.typeAnnulation.type})}}
          <a href="https://support.lightyshare.com/article/93-comment-annuler-une-reservation-validee" target="__blank">
            {{$t('modals.cancel.loc.moreInfos')}}
          </a>
        </p>
        <p>
          {{ $t('modals.cancel.loc.risk', {TTC: formatPrice(ask.prix_total_ttc), nbDays: nbDaysBeforeDeparture }) }}
        </p>
        <ul>
          <li v-if="termDetails && termDetails.penaltyPercent">
            {{$t('modals.cancel.loc.percentage.' + termDetails.penaltyPercent, {TTC: formatPrice(termDetails.penaltyAmount)})}}
          </li>
          <li>
            {{$t('modals.cancel.loc.badNotation')}}
          </li>
        </ul>
      </div>
      <div v-else>
        <p>
          <strong>
            {{$t('modals.cancel.tenant.limit', {dateLimit: timeAfter3hourForPaidAt})}}
          </strong>
        </p>
      </div>
      <el-form-item :label="$t('modals.cancel.tenant.leaveMessage', {user: username})">
        <el-input v-model="edit.message" type="textarea" :placeholder="$t('modals.cancel.tenant.placeholder', {user: username})" @change="change"></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<style scoped>
</style>