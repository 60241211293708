<script>
export default {
  name: "breadcrumb",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    isNotLastItem($index) {
      return $index < this.items.length - 1
    }
  },
  computed: {
    formattedItems () {
      return this.items.map((item, index) => {
        const classes = item.loading ? 'inline-content-loading' : ''
        const isLast = index === this.items.length - 1
        return {
          ...item,
          isLast,
          classes
        }
      })
    }
  }
}
</script>

<template>
  <div class="lightyshare_breadcrumb">
    <span v-for="(item, index) in formattedItems" :key="index">
      <span v-if="!item.isLast">
        <router-link v-if="item.to" :to="item.to" class="c-black breadcrumb-item" :class="item.classes">
          <span v-if="item.icon" class="icomoon" v-html="item.icon"></span>
          <span v-if="item.label">{{ item.label }}</span>
        </router-link>
        <span v-else class="c-black breadcrumb-item">
          <span v-if="item.icon" class="icomoon" v-html="item.icon"></span>
          <span v-if="item.label">{{ item.label }}</span>
        </span>
        <span class="icomoon breadcrumb-icon">&#xe930</span>
      </span>
      <span v-else class="breadcrumb-item last-item" :class="item.classes">
        <span v-if="item.label">{{ item.label }}</span>
      </span>
    </span>
  </div>
</template>

<style scoped>

</style>