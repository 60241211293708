<script>
export default {
  name: "PageWithStickyPanelLayout",
  props: {
    templateClasses: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class="askTemplate" :class="templateClasses">
    <div>
      <slot name="beforePanel"></slot>
      <div class="only-phone">
        <slot name="panel" ></slot>
      </div>
      <slot name="afterPanel"></slot>
    </div>
    <div>
      <div class="only-desktop sticky-resume">
        <slot name="panel"></slot>
      </div>
    </div>
    <div class="ask-primary-action only-phone">
      <slot name="phone-actions">
      </slot>
    </div>
  </div>
</template>

<style scoped>

</style>