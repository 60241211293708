import VuexORMAxios from "@vuex-orm/plugin-axios";
import { createStore } from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMSearch from '@vuex-orm/plugin-search'
import axios from "axios";
import MonthlyBilling from "../modules/billing/services/MonthlyBilling.js";
import Bill from "../modules/billing/services/Bill.js";
import Ads from "../modules/ad/services/Ads.js";
import Society from "../modules/user/services/Society.js";
import User from "../modules/user/services/User.js";
import Signup from "../modules/signup/services/Signup.js";
import Billing from "../modules/billing/services/Billing.js";
import ImageProfil from "../modules/user/services/ImageProfil.js";
import Auth from "../modules/auth/services/Auth";
import Computed from "../modules/economic/services/Computed";
import Visite from "../modules/analytics/services/Visite.js";
import Media from "../modules/media/services/Media.js";
import Bundle from "../modules/ad/services/Bundle.js";
import BundleItem from "../modules/ad/services/BundleItem.js";
import BillingAccount from "../modules/billing/services/BillingAccount.js";
import Rental from "~/modules/ask/services/Rental.js";
import Inventory from "~/modules/ad/services/Inventory.js";
import AdUnavailability from "~/modules/ad/services/AdUnavalability.js";
import Cart from "~/modules/cart/services/Cart.js";
import CartItem from "~/modules/cart/services/CartItem.js";
import Mangopay from "~/modules/payment/services/Mangopay.js";
import Conflict from "~/modules/ad/services/Conflict";
import ProductMigration from "~/modules/ad/services/ProductMigration";
import Modele from "~/modules/ad/services/Modele.js";
import DemandeAjoutModele from "~/modules/ad/services/DemandeAjoutModele.js";
import Carousel from "~/modules/promotions/services/Carousel.js";
import Brand from "~/modules/categories/services/Brand.js";
import Category from "~/modules/categories/services/Category.js";
import Notification from "~/modules/notifications/services/Notification.js";

VuexORM.use(VuexORMAxios, { axios })
VuexORM.use(VuexORMSearch)
const database = new VuexORM.Database()
const services = import.meta.glob('/modules/**/services/*.js')
// const services2 = import.meta.glob('/modules/**/services/*.js', { import: 'default' })
// console.log(services['/modules/ad/services/Ads.js']())
// console.log(services2)
const metas = Object.keys(services).map((service) => {
  const folders = service.split('/').filter((el) => el !== '')
  const moduleName = folders[0]
  const file = folders[folders.length -1]
  const name = file.split('.')[0]
  const type = name === 'index' ? 'default' : name.match('^[A-Z]+') ? 'VuexOrm' : 'VuexModule'
  return {
    name,
    type,
    moduleName,
    file,
    component: services[service]
  }
})
const ORMEntities = metas.reduce((entities, meta) => {
  if (meta.name === 'index') {
    return entities
  }
  if (!entities[meta.name]) {
    entities[meta.name] = {
      VuexOrm: false,
      VuexModule: false,
      info: meta
    }
  }
  entities[meta.name][meta.type] = meta.component
  return entities
}, {})


// database.register(Chat)
database.register(ProductMigration)
database.register(Bill)
database.register(Rental)
database.register(Media)
database.register(Ads)
database.register(Modele)
database.register(Brand)
database.register(Category)
database.register(Inventory)
database.register(Bundle)
database.register(BundleItem)
database.register(MonthlyBilling)
database.register(Society)
database.register(User)
database.register(Billing)
database.register(ImageProfil)
database.register(Auth)
database.register(Signup)
database.register(Visite)
database.register(Computed)
database.register(BillingAccount)
database.register(AdUnavailability)
database.register(Cart)
database.register(CartItem)
database.register(Mangopay)
database.register(Conflict)
database.register(DemandeAjoutModele)
database.register(Carousel)
database.register(Notification)

const VuexModules = []
// for (const entity in ORMEntities) {
//   let e = ORMEntities[entity]
//   let storeModule = false
//   let ormModule = false
//   if (e.VuexModule) {
//     storeModule = true // e.VuexModule
//     // storeModule = storeModule.default
//   }
//   if (e.VuexOrm) {
//     ormModule = true //e.VuexOrm
//     // ormModule = ormModule.default
//   }
//   if (ormModule && storeModule) {
//     Promise.all([e.VuexModule(), e.VuexOrm()]).then((pro) => {
//       console.log('Register entity ' + e.info.name + ' with his Vuex module')
//       database.register(pro[0], pro[1])
//     })
//
//     // database.register(ormModule, storeModule)
//   } else if (ormModule) {
//     e.VuexOrm().then((orm) => {
//       console.log('Register entity ' + e.info.name + ' without Vuex module')
//       console.log(orm)
//       database.register(orm.default)
//     })
//   } else if (storeModule) {
//     e.VuexModule().then((mod) => {
//       console.log('Add VueX module named ' + e.info.name)
//       store.registerModule(e.info.name, mod)
//     })
//   }
// }


// Create Vuex Store and register database through Vuex ORM.
const store = createStore({
  // modules: VuexModules,
  plugins: [VuexORM.install(database)]
})

// store.ho

export default store
