<script>
import breadcrumb from "~/components/breadcrumb.vue";
import {asksStatusSlug, asksStatusTitles, reverseMap} from "~/modules/ask/maps/asks-maps.js";
import Rental from "~/modules/ask/services/Rental.js";
import formHandler from "~/mixins/formHandler";
import DateRangePicker from "~/components/DateRangePicker.vue";
import AskEdit from "~/modules/ask/components/AskEdit.vue";
import Auth from "~/modules/auth/services/Auth";


export default {
  name: "OwnerAsk",
  mixins: [formHandler],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  components: {
    AskEdit,
    DateRangePicker,
    breadcrumb
  },
  data() {
    return {
      date: null,
    };
  },
  computed: {
    auth () {
      return Auth.query().first()
    },
    // Auth() {
    //   return Auth
    // },
    apiUrl () {
      return '/api/user/ask'
    },
    Model () {
      return Rental
    },
    // fr () {
    //   return fr
    // },
    fr () {
      return {}
    },
    breadcrumbItems () {
      return [
        { to: { name: 'all-asks'}, icon: '&#xea16' },
        { label: this.statusTitle, to: this.statusTo },
        { label: this.askTitle, loading: this.askTitle === '' }
      ]
    },
    askTitle () {
      return this.ask && this.ask.titre_location ? this.ask.titre_location : ''
    },
    statusTitle () {
      const reversedStatusSlugMap = reverseMap(asksStatusSlug)
      const keySlug = reversedStatusSlugMap[this.$route.params.status]
      return asksStatusTitles[keySlug]
    },
    statusTo () {
      const reversedStatusSlugMap = reverseMap(asksStatusSlug)
      return {
        name: reversedStatusSlugMap[this.$route.params.status]
      }
    },
    ask () {
      return Rental.query().with(['locataire.ImageProfil', 'proprietaire.ImageProfil']).find(this.$route.params.id)
    }
  },
  methods: {
    onChangeCartItems (ask, item) {
      console.log('change cart items')
      console.log(ask)
      console.log(item)
      const dataFormContent = {}
      if (ask && ask.panier && ask.panier.paniersAnnonce && ask.panier.paniersAnnonce.length) {
        dataFormContent['cartItems'] = ask.panier.paniersAnnonce.map((item) => {
          return {
            'id': item.id,
            'quantity': item.quantity,
            'valide': item.valide
          }
        })
        dataFormContent['save'] = true
        this.sendForm(true, dataFormContent, null, 'Cart')
      }

      // dataFormContent = {
      //   'id': item.id,
      //   'quantity': item.quantity,
      //   'valide': item.valide
      // }
    }
  }
}
</script>

<template>
  <h3 class="parameters-title">Réservation</h3>
  <breadcrumb :items="breadcrumbItems"></breadcrumb>
  <AskEdit
    :ask="ask"
    :auth="auth"
    :available-cart-quantity="response && response.availableCartQuantity ? response.availableCartQuantity : {}"
    @changeCartItems="onChangeCartItems"
  >
  </AskEdit>

</template>

<style scoped>

</style>