<script>
import AskCartItem from "~/modules/ask/components/AskCartItem.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";
import Rental from "~/modules/ask/services/Rental";
import formHandler from "~/mixins/formHandler";

export default {
  name: "AskCart",
  components: {ModalLayout, AskCartItem},
  mixins: [formHandler],
  emits: ['update:modelValue', 'change'],
  props: {
    availableCartQuantity: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modifiable: {
      type: Boolean,
      default: false
    },
    ask: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modelValue: Object
  },
  data () {
    return {
      equipmentModal: false,
      edit: null
    }
  },
  watch: {
    ask: {
      immediate: true,
      deep:true,
      handler (val) {
        // this.edit = val
        // console.log()
        if (val && !this.edit) {
          this.edit = JSON.parse(JSON.stringify(val))
        }
        // if (val && this.edit && !this.edit.panier && val.panier) {
        //   console.log('update with ask')
        //   this.edit.panier = val.panier
        // }
        // if (val && this.edit && this.edit.panier && val.panier && val.panier.paniersAnnonce && !this.edit.panier.paniersAnnonce) {
        //   console.log('update with ask')
        //   this.edit.panier.paniersAnnonce = val.panier.paniersAnnonce
        // }
        // if (val && this.edit && this.edit.panier && val.panier && val.panier.paniersAnnonce && !this.edit.panier.paniersAnnonce) {
        //   console.log('update with ask')
        //   this.edit.panier.paniersAnnonce = val.panier.paniersAnnonce
        // }
        // if (val && this.edit && this.edit.panier && val.panier && val.panier.paniersAnnonce && val.panier.paniersAnnonce.annonces && !this.edit.panier.paniersAnnonce) {
        //   console.log('update with ask')
        //   this.edit.panier.paniersAnnonce = val.panier.paniersAnnonce
        // }
        // if (val && this.edit && this.edit.panier && this.edit.panier.paniersAnnonce && this.edit.panier.paniersAnnonce.annonces && val.panier && val.panier.paniersAnnonce && val.panier.paniersAnnonce.annonces && val.panier.paniersAnnonce.annonces.bundle && val.panier.paniersAnnonce.annonces.bundle && !this.edit.panier.paniersAnnonce.annonces.bundle) {
        //   console.log('update with ask')
        //   this.edit.panier.paniersAnnonce.annonces.bundle = val.panier.paniersAnnonce.annonces.bundle
        // }
        // if (val && this.edit && this.edit.panier && this.edit.panier.paniersAnnonce && this.edit.panier.paniersAnnonce.annonces && this.edit.panier.paniersAnnonce.annonces.bundle && val.panier && val.panier.paniersAnnonce && val.panier.paniersAnnonce.annonces && val.panier.paniersAnnonce.annonces.bundle && val.panier.paniersAnnonce.annonces.bundle.bundleItems && !this.edit.panier.paniersAnnonce.annonces.bundle.bundleItems) {
        //   console.log('update with ask')
        //   this.edit.panier.paniersAnnonce.annonces.bundle.bundleItems = val.panier.paniersAnnonce.annonces.bundle.bundleItems
        // }
        if (val && this.edit) {
          let data = JSON.parse(JSON.stringify({
            ...this.edit,
            ...val
          }))
          this.edit = data
        }
      }
    },
  },
  methods: {
    syncEdit (data) {
      this.edit = JSON.parse(JSON.stringify(data))
      // this.edit = data
    },
    updateItem (item) {
      // console.log(this.$refs['askCartItem' + item.id])
      if (this.$refs['askCartItem' + item.id]) {
        this.$refs['askCartItem' + item.id][0].updateItem(item)
      }
      // this.$refs['askCartItem' + item.id].updateItem(item)
    },
    initEdit () {

    },
    changeItem (item) {
      console.log(item)
      const AskAdIndex = this.edit.panier.paniersAnnonce.findIndex((cartItem) => {
        return cartItem && cartItem.id === item.id
      })
      console.log(AskAdIndex)
      if (AskAdIndex !== -1) {
        this.edit.panier.paniersAnnonce[AskAdIndex] = item
        this.$emit('change', this.edit, item)
      }
    },
    toogleAdActive (value, item) {
      const AskAdIndex = this.edit.panier.paniersAnnonce.findIndex((cartItem) => {
        return cartItem && cartItem.annonces && cartItem.annonces.id === item.annonces.id
      })
      if (AskAdIndex !== -1) {
        this.edit.panier.paniersAnnonce[AskAdIndex].valide = value
        this.$emit('change', this.edit)
      }
      // console.log('active')
      // console.log(item)
      // console.log(value)
      // console.log(this.edit.panier.paniersAnnonce[AskAdIndex].valide)
    },
    async openEquipmentModal (equipment) {
      this.equipmentModal = equipment
    },
    async closeEquipmentModal () {
      this.equipmentModal = false
    },
  },
  computed: {
    Model () {
      return Rental
    },
    formSave () {
      return true
    },
    formKey () {
      return 'cartItem'
    },
    apiUrl () {
      return '/api/user/ask'
    },
    itemClasses () {
      if (this.modifiable) {
        return 'selectable'
      }
    },
    cartItemsCategories () {
      if (this.ask && this.ask.panier && Array.isArray(this.ask.panier.paniersAnnonce)) {
        return this.ask.panier.paniersAnnonce.reduce((categorize, cartItem, key) => {
          let hasCategory = categorize.findIndex((category) => {
            return category && cartItem && cartItem.annonces && cartItem.annonces.souscategorieannonce && category.id === cartItem.annonces.souscategorieannonce.id
          })
          cartItem['key'] = key
          if (hasCategory === -1) {
            let id = null
            let name = null

            if (cartItem && cartItem.annonces && cartItem.annonces.souscategorieannonce) {
              id = cartItem.annonces.souscategorieannonce.id
              name = cartItem.annonces.souscategorieannonce.nom
            }
            let editCartItem = {}
            if (this.edit && this.edit.panier && this.edit.panier.paniersAnnonce && this.edit.panier.paniersAnnonce[key]) {
              editCartItem = this.edit.panier.paniersAnnonce[key]
            }
            categorize.push({
              id: id,
              name: name,
              items: [{...cartItem, ...editCartItem}]
            })
          } else {
            categorize[hasCategory].items.push(cartItem)
          }
          return categorize
        }, [])
      } else {
        return []
      }
    }
  }
}
</script>

<template>
  <div>
    <div v-for="category in cartItemsCategories">
      <div>
        <h3 class="cart-item-content">{{ category.name }}</h3>
      </div>
      <div class="cart-items">
        <AskCartItem v-for="item in category.items" :key="item.key" :ref="'askCartItem' + item.id" :item="item" class="cart-item" :class="itemClasses" :selectable="modifiable" :ask="ask.panier.paniersAnnonce[item.key]" @changeItem="changeItem" @toogle-item="toogleAdActive" @show-equipments="openEquipmentModal" :availableItemQuantity="availableCartQuantity[item.annonces.id] ? availableCartQuantity[item.annonces.id] : {}">
        </AskCartItem>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <ModalLayout :show="equipmentModal" @close="closeEquipmentModal" modal-class="full-width-modal">
      <template #header>
        <h3><span class="icon-kit">&#xe925</span>Inclus dans l'annonce</h3>
      </template>
      <template #body>
        <el-scrollbar ref="scrollbarRef" max-height="80vh" class="remove-modal-padding">
          <div class="equipment-item-row">
            <div>Qté</div>
            <div>Equipement</div>
          </div>
          <div v-for="equipment in equipmentModal" class="equipment-item-row">
            <div>{{ equipment.quantity }}</div>
            <div>{{ equipment.nomEquipement }}</div>
          </div>
        </el-scrollbar>
      </template>
    </ModalLayout>
  </Teleport>
</template>

<style scoped>

</style>