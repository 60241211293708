import { createApp } from 'vue'

import fr from 'element-plus/dist/locale/fr.mjs'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/display.css'
import axios from 'axios'
import VueDatePicker from '@vuepic/vue-datepicker';
import nationalities from "~/nationalities.json";
import countries from "~/countries.json";
// import '@vuepic/vue-datepicker/dist/main.css'
// import '~/styles/element.scss'
import { createWebHistory, createRouter } from 'vue-router'
import moment from 'moment/min/moment-with-locales.min.js'
import store from "./services/index";
moment.locale('fr')

import mitt from 'mitt';
const emitter = mitt();
import App from './App.vue'
import VueTelInput from 'vue-tel-input';
// import 'vue-tel-input/vue-tel-input.css';
const globalOptions = {
	mode: 'auto',
};

import routes from './router'
import chatRoom from "./components/chatRoom.vue";
import "./chat.scss"
// import ChatSection from './components/chat-section.vue'
import TopBar from "./components/topBar.vue";
import { createI18n } from "vue-i18n"
import MessagesFr from "../src/LightyShare/LightyShareBundle/Resources/translations/messages.fr.yml"
import LocationsFr from "../src/LightyShare/LightyShareBundle/Resources/translations/locations.fr.yml"
import dropdownDirective from "./directives/dropdownDirective";
import clickOutside from "./directives/clickOutside.js";

import * as Sentry from "@sentry/vue";
import "./styles/ui.scss"
// import 'element-plus/es/components/message/style/css'
// import ElementPlus from "element-plus";
// import fr from "element-plus/es/locale/lang/fr";
// import 'element-plus/dist/index.css'

// import dropdownDirective from "./directives/dropdownDirective";

// console.log(manageOwnRentals.props)
// console.log('chat console')
// console.log(window.$lightyshareConfig)
// let props = {}
// const rootElement = document.getElementById('manage-rental')
// const propsToInject = Object.keys(manageOwnRentals.props).filter((p) => {
//   return rootElement.getAttribute(p)
// }).map((p) => {
//   return {
//     key: p,
//     val: rootElement.getAttribute(p)
//   }
// })
// propsToInject.forEach((p) => {
//   props[p.key] = p.val
// })
// const chatRoom = {
//   template: '<div><h1>chat room</h1></div>',
// }
if (typeof window.Beacon === 'function') {
	window.Beacon('config', {
		hideFABOnMobile: true,
		hideFABLabelOnMobile: true
	})
}
const messages = {
  fr: { ...LocationsFr, ...MessagesFr},
  ja: {
    message: {
      hello: 'こんにちは、世界'
    }
  }
}
console.log('before custom formatter')
function customMessageCompiler(message) {
	return (values) => {
		let result = message;

		for (const key in values) {
			if (Object.prototype.hasOwnProperty.call(values, key)) {
				const replacement = `%${key}%`;
				result = result.replace(replacement, values[key]);
			}
		}

		return result;
	};
}
const i18n = createI18n({
	// legacy: true,
	locale: 'fr', // set locale
  fallbackLocale: 'fr', // set fallback locale
  messages, // set locale messages
	messageCompiler: customMessageCompiler
})

const router = createRouter({
  history: createWebHistory(),
  routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		// if (document.getElementById('main-scroll')) {
		// 	return {
		// 		el: '#main-scroll',
		// 		// 10px above the element
		// 		top: 10,
		// 	}
		// }
	},
})


const app = createApp(App)

// throw new Error('coucou sentry')
const topBar = createApp(TopBar, {
	routerLinks: []
})
console.log('active sentry')
console.log(import.meta.env.VITE_SENTRY_ACTIVE)
if (import.meta.env.VITE_SENTRY_ACTIVE) {
	Sentry.init({
		app: [app, topBar],
		release: import.meta.env.VITE_SENTRY_RELEASE,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration({
				maskAllText: false,
			}),
		],
		beforeSend(event) {
			// Ignorer les événements qui ne sont pas des erreurs
			if (event.level !== 'error') {
				return null;
			}
			return event;
		},

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["http:\/\/localhost", /^https:\/\/lightyshare\.com/, /^https:\/\/www\.lightyshare\.com/],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});

}
// Sentry.init({
// 	app,
// 	dsn: "https://873b2281496e4f0faf6f107549ec3627@o247139.ingest.us.sentry.io/5916928",
// 	integrations: [
// 		Sentry.browserTracingIntegration({ router }),
// 		// Sentry.replayIntegration(),
// 	],
//
// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: 1.0,
//
// 	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
// 	tracePropagationTargets: ["htts:\/\/localhost:8000", /^https:\/\/lightyshare.com/],
//
// 	// Capture Replay for 10% of all sessions,
// 	// plus for 100% of sessions with an error
// 	replaysSessionSampleRate: 0.1,
// 	replaysOnErrorSampleRate: 1.0,
// });

// throw new Error('coucou sentry 2')

app.config.globalProperties.emitter = emitter
if (window.$data) {
  app.config.globalProperties.$symfony = window.$data;
  topBar.config.globalProperties.$symfony = window.$data;
	if (window.$data.auth && window.$data.auth.id) {
		Sentry.setUser({
			id: window.$data.auth.id + '',
			username: window.$data.auth.prenom + ' ' + window.$data.auth.nom,
			email: window.$data.auth.email + '',
		});
	}
}

app.config.globalProperties.path = function (route, params = {}) {
	const name = route
	if (typeof app.config.globalProperties.$symfony.router[route] === 'undefined') {
		console.error(`This route : ${route} doesn't exist`)
		return ''
	} else {
		route = app.config.globalProperties.$symfony.router[route]
	}
	let path = route['path'];
	const args = route['path'].match(/{[a-z]{1,}}/gm)
	if (args && args.length > 0) {
		args.forEach((arg) => {
			const key = arg.replace('{', '').replace('}', '')
			if (typeof params[key] !== 'undefined') {
				path = path.replace(arg, params[key])
			} else if (typeof route['defaults'][key] !== 'undefined') {
				path = path.replace(arg, route['defaults'][key])
			} else {
				console.error(`No param named ${key} set for ${name} route`)
			}
		})
	}
	return path
}
topBar.config.globalProperties.path = function (route, params = {}) {
	const name = route
	if (typeof app.config.globalProperties.$symfony.router[route] === 'undefined') {
		console.error(`This route : ${route} doesn't exist`)
		return ''
	} else {
		route = app.config.globalProperties.$symfony.router[route]
	}
	let path = route['path'];
	const args = route['path'].match(/{[a-z]{1,}}/gm)
	if (args && args.length > 0) {
		args.forEach((arg) => {
			const key = arg.replace('{', '').replace('}', '')
			if (typeof params[key] !== 'undefined') {
				path = path.replace(arg, params[key])
			} else if (typeof route['defaults'][key] !== 'undefined') {
				path = path.replace(arg, route['defaults'][key])
			} else {
				console.error(`No param named ${key} set for ${name} route`)
			}
		})
	}
	return path
}
// topBar.use(router)
topBar.use(i18n)
topBar.directive('dropdown', dropdownDirective)
topBar.directive('clickOutside', clickOutside)
app.config.globalProperties.$currencyFormat = new Intl.NumberFormat("fr-FR", {
	style: 'currency',
	currency: 'EUR'
})
app.config.globalProperties.asset = function (path) {
    return '/' + path
}
topBar.config.globalProperties.asset = function (path) {
    return '/' + path
}
app.config.globalProperties.Beacon = window.Beacon
topBar.config.globalProperties.Beacon = window.Beacon

// const path = app.config.globalProperties.$generatePath('lighty_share_catalogue', {page: 3})

// app.use(ElementPlus, {
//   // locale: fr
// })



app.use(router)
app.use(store)
app.use(i18n)
app.use(ElementPlus, { locale: fr })
app.use(VueTelInput, globalOptions);
app.directive('dropdown', dropdownDirective)
app.directive('clickOutside', clickOutside)
// app.directive('dropdown', dropdownDirective)
app.config.globalProperties.$moment=moment
app.config.globalProperties.$axios=axios
app.config.globalProperties.$nationalities=nationalities
app.config.globalProperties.$countries=countries
app.config.globalProperties.$lightyshareConfig=window.$lightyshareConfig

app.component('VueDatePicker', VueDatePicker);
const hasAppSection = document.getElementById('app-section');
if (hasAppSection) {
	app.mount('#app-section')
}
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}
const hasTopBar = document.getElementById('top-bar');
if (hasTopBar) {
	topBar.mount('#top-bar')
}
