import User from "../../user/services/User.js";
import {Model} from "@vuex-orm/core";

export default class Notification extends Model {
  static entity = 'notifications'

  static apiConfig = {
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    baseURL: '/api',
    dataKey: 'notifications',
    // actions: {
    //   fetch: {
    //     method: 'get',
    //     url: 'comput'
    //   }
    // }
  }

  static fields () {
    return {
      newRentals: this.attr(0),
      newMessages: this.attr(0),
      newLocations: this.attr(0),
      newPanierSessions: this.attr(0),
    }
  }
}