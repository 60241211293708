AdminDashboard.vue<template>
  <div class="dashboard-section">
    <div class="">
      <div class="boutique-header">
<!--        <h3 class="parameters-title">Tableau de bord</h3>-->
        <h4 class="parameters-subtitle">Tableau de bord</h4>
        <el-date-picker
            v-model="statisticsInterval"
            type="daterange"
            unlink-panels
            range-separator="à"
            start-placeholder="Début"
            end-placeholder="Fin"
            :shortcuts="shortcuts"
            :size="'large'"
            @change="loadDashboard"
        />
<!--        <el-button @click="loadDashboard">Recharger</el-button>-->
      </div>
      <div class="statistiques-section">
        <el-button @click="sendError">Test error</el-button>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="statistiques">
              <h2>Statistiques</h2>
              <div class="box-area">
                <div class="box">
                  <h3><span>{{ displayGmv + ' €' }}</span></h3>
                  <p>GMV</p>
                </div>
                <div class="box">
                  <h3><span>{{ displayTotalFees + '€'}}</span></h3>
                  <p>Frais de services</p>
                </div>
<!--                <div class="box">-->
<!--                  <h3 :class="timeResponseClass"><span>{{ displayTimeResponse }}</span></h3>-->
<!--                  <p>Délai de réponse</p>-->
<!--                </div>-->
<!--                <div class="box">-->
<!--                  <h3 :class="receivedRentalClass"><span>{{ receivedRental}}</span></h3>-->
<!--                  <p>Demandes reçues</p>-->
<!--                </div>-->
<!--                <div class="box">-->
<!--                  <h3 :class="approveRentalRateClass"><span>{{ approveRentalRate }}%</span></h3>-->
<!--                  <p>Locations approuvées</p>-->
<!--                </div>-->
<!--                <div class="box">-->
<!--                  <h3 :class="doneRentalRateClass"><span>{{ doneRentalRate }}%</span></h3>-->
<!--                  <p>Locations réalisées</p>-->
<!--                </div>-->
              </div>
            </div>
          </div>
<!--          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">-->
<!--            <div class="chart">-->
<!--              <BarChart :dataset="chartDataset"></BarChart>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>

  </div>
</template>
<script>

  export default {
    name: "ShopDashboard",
    components: {
    },
    data () {
      return {
        statisticsInterval: [
            new Date(),
            new Date()
        ],
        shortcuts: [
          {
            text: 'Semaine dernière',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            }
          }
        ],
        GMV: null,
        TotalFees: null,
        rentalWaiting: null,
        rentalValidated: null,
        tauxReponses: null,
        timeResponse: null,
        user: null,
        statisticLocationCharts: null
      }
    },
    computed: {
      displayGmv () {
        return this.GMV ? this.GMV : 0
      },
      displayTotalFees () {
        return this.TotalFees ? this.TotalFees : 0
      },
      rating () {
        return this.user && this.user.notationAverage ? this.user.notationAverage : 0
      },
      ratingClass () {
        if (this.rating >= 4) {
          return 'green'
        }
        if (this.rating >= 2.5) {
          return 'yellow'
        }
        return 'red'
      },
      displayTauxResponse () {
        return Math.round(this.tauxReponses)
      },
      tauxResponseClass () {
        if (this.displayTauxResponse > 75) {
          return 'green'
        }
        if (this.displayTauxResponse > 50) {
          return 'yellow'
        }
        return 'red'
      },
      displayTimeResponse () {
        if (this.timeResponse === null) {
          return '-'
        }
        if (this.timeResponse <= 3600) {
          return 'Dans l’heure'
        }
        if (this.timeResponse <= (3600 * 24)) {
          let time = Math.round(this.timeResponse / 3600)
          if (time === 1) {
            time += ' heure'
          } else {
            time += ' heures'
          }
          return time
        }
        let time = Math.round(this.timeResponse / (3600 * 24))
        if (time === 1) {
          return time + ' jour'
        }
        return time + ' jours'
      },
      timeResponseClass () {
        if (this.timeResponse === null) {
          return 'green'
        }
        if (this.timeResponse <= 3600) {
          return 'yellow'
        }
        if (this.timeResponse <= (3600 * 24)) {
          return 'red'
        }
        return 'red'
      },
      approveRentalRate () {
        if (this.user && this.user.acceptedLocationsRate) {
          return Math.round(this.user.acceptedLocationsRate)
        } else {
          return '-'
        }
      },
      approveRentalRateClass () {
        if (this.user && this.user.acceptedLocationsRate) {
          if (this.user.acceptedLocationsRate > 75) {
            return 'green'
          }
          if (this.user.acceptedLocationsRate > 50) {
            return 'yellow'
          }
          return 'red'
        } else {
          return 'green'
        }
      },
      doneRentalRate () {
        if (this.user && this.user.totalLocations > 0) {
          return Math.round(((this.user.nbRentalsAsOwnerDone / this.user.totalLocations) * 100))
        } else {
          return '-'
        }
      },
      doneRentalRateClass () {
          if (this.doneRentalRate > 75) {
            return 'green'
          }
          if (this.doneRentalRate > 50) {
            return 'yellow'
          }
          return 'red'
      },
      receivedRental () {
        return this.user && this.user.totalLocations ? this.user.totalLocations : '-'
      },
      receivedRentalClass () {
        return this.user && this.user.totalLocations ? 'green' : 'yellow'
      },
      chartDataset () {
        if (this.statisticLocationCharts === null) {
          return [
              [0, 0, 0, 0, 0, 0],
              [0, 0, 0, 0, 0, 0]
          ]
        } else {
          return [
              this.statisticLocationCharts.map((data) => data.rental_reveived),
              this.statisticLocationCharts.map((data) => data.rental_done)
          ]
        }
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler (val) {
          this.loadDashboard().catch(this.handleError)
          // if (val.path.)

        }
      }
    },
    methods: {
      sendError () {
        throw new Error('Test error')
      },
      handleError (e) {
        console.error(e)
      },
      async loadDashboard () {
        const statistics = await this.$axios.get('/api/admin/statistics', {
          params: {
            from: this.statisticsInterval[0].getTime()/1000,
            to: this.statisticsInterval[1].getTime()/1000,
          }
        })
        if (statistics.data) {
          this.GMV = statistics.data.payInSum
          this.TotalFees = statistics.data.feesSum
        }
        console.log(statistics)
      }
    }
  }
</script>
<style>

</style>
