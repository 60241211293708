<script>

import UserTicketCard from "~/modules/user/components/UserTicketCard.vue";
import OwnerAskStatus from "~/modules/ask/components/OwnerAskStatus.vue";
import OwnerAskDropdown from "~/modules/ask/components/OwnerAskDropdown.vue";
import ResumeListItem from "~/modules/cart/components/ResumeListItem.vue";
import {asksStatusSlug} from "~/modules/ask/maps/asks-maps.js";

export default {
  name: "AsksTable",
  components: {ResumeListItem, OwnerAskDropdown, OwnerAskStatus, UserTicketCard},
  emits: ['row-click'],
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    routerLink: {
      type: Boolean,
      default: true
    },
    asks: {
      type: Array,
      default () {
        return []
      }
    },
    columnsFilter: {
      type: Array,
      default () {
        return ['locataire', 'status', 'titre_location', 'list', 'departUnix', 'finUnix', 'ownerRevenuHT', 'action']
      }
    }
  },
  methods: {
    hasColumn (column) {
      return this.columnsFilter.includes(column)
    },
    formatRentalTitle (title) {
      if (title && title.length > 27) {
        return title.substring(0, 27) + '...'
      } else {
        return title
      }
    },
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    handleClick (id) {
      if (this.$refs['ActionSelect' + id]) {
        this.$refs['ActionSelect' + id].handleOpen()
      }
    },
    onCellClick (row, column) {
      if (column.property !== 'action') {
        if (this.routerLink) {
          this.$router.push({
            name: 'ask-edit',
            params: {
              id: row.id,
              status: asksStatusSlug[this.$route.name]
            }
          })
        } else {
          this.$emit('row-click', row, column)
        }
      }
      // console.log('row click')
      // console.log(row)
      // console.log(column)
    },
    onRowClick (row) {
      // console.log('onclick row')
      // console.log(this.$events)
      // console.log(row)
      // window.location.href = '/user/location/' + row.id
    }
  }
}
</script>

<template>
  <el-table :data="asks" style="width: 100%; " :table-layout="'auto'" :default-sort="defaultOrder" @cell-click="onCellClick" :row-class-name="tableRowClassName" class="pointer-row">
    <template #empty>
      <slot name="empty"></slot>
    </template>
    <el-table-column v-if="hasColumn('locataire')" prop="locataire" label="Locataire" sortable="custom" min-width="210">
      <template #default="{row}">
        <user-ticket-card :user="row.locataire" :show-company-icon="false"></user-ticket-card>
      </template>
    </el-table-column >
    <el-table-column v-if="hasColumn('proprietaire')" prop="proprietaire" label="Propriétaire" sortable="custom" min-width="210">
      <template #default="{row}">
        <user-ticket-card :user="row.proprietaire" :show-company-icon="false"></user-ticket-card>
      </template>
    </el-table-column >
    <el-table-column v-if="hasColumn('status')" prop="status" label="Status" sortable="custom" min-width="60">
      <template #default="{row}">
        <OwnerAskStatus :ask="row"></OwnerAskStatus>
      </template>
    </el-table-column >
    <el-table-column v-if="hasColumn('titre_location')" prop="titre_location" label="Projet" min-width="120">
      <template #default="{row}">
        <span>{{formatRentalTitle(row.titre_location)}}</span>
      </template>
    </el-table-column>
    <el-table-column v-if="hasColumn('list')" prop="list" label="Liste" min-width="300">
      <template #default="{row}">
        <ResumeListItem :list-items="row.panier.paniersAnnonce"></ResumeListItem>
      </template>
    </el-table-column>
    <el-table-column v-if="hasColumn('departUnix')" prop="departUnix" label="Départ" sortable="custom" min-width="150">
      <template #default="{row}">
        <span v-html="$moment.unix(row.departUnix).format('ddd DD MMM')"></span>
      </template>
    </el-table-column>
    <el-table-column v-if="hasColumn('finUnix')" prop="finUnix" label="Retour" sortable="custom" min-width="150">
      <template #default="{row}">
        <span v-html="$moment.unix(row.finUnix).format('ddd DD MMM')"></span>
      </template>
    </el-table-column>
    <el-table-column v-if="hasColumn('ownerRevenuHT')"  prop="ownerRevenuHT" label="Montant" :sortable="'custom'" min-width="80">
      <template #default="{row, column}">
        <span v-html="$currencyFormat.format(row.ownerRevenuHT)"></span>
      </template>
    </el-table-column>
    <el-table-column fixed="right" width="30">
      <template #default="scope">
        <el-dropdown trigger="click" :ref="'ActionSelect' + scope.row.id">
          <div class="dropdown">
            <button class="dropdown-edit-toogle" style="padding: 5px;" @click.stop="handleClick(scope.row.id)">
                <span class="icon-kit">
                  &#xe9a5
                </span>
            </button>
          </div>
          <template #dropdown>
            <OwnerAskDropdown :ask="scope.row"></OwnerAskDropdown>
          </template>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<style lang="scss">
.el-table__row:nth-child(2n+1) {
  //--el-table-tr-bg-color: #0e84b5;
  .td.el-table__cell {
    //--el-table-tr-bg-color: #0e84b5;
  }
  /*background-color: #0e84b5;*/
}
.el-table__header-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__header-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__header-wrapper tr:nth-child(2n+1) , .el-table__header-wrapper tr:nth-child(2n+1), .el-table__body-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__body-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__body-wrapper tr:nth-child(2n+1) , .el-table__body-wrapper tr:nth-child(2n+1), .el-table__footer-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__footer-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__footer-wrapper tr:nth-child(2n+1) , .el-table__footer-wrapper tr:nth-child(2n+1) {
  //--el-table-tr-bg-color: #0e84b5;
  //background: var(--el-table-tr-bg-color);
}
</style>