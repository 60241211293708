<script>
import Media from "~/modules/media/services/Media.js";
import { ref, reactive } from 'vue'

export default {
  name: "ProductMediasEdit",
  data () {
    return {
      dialogVisible: false,
      dialogImageUrl: null,
      dialogMedia: null,
      isDeleting: reactive({})
    }
  },
  props: {
    modelValue: {
      type: Array,
      default () {
        return []
      }
    },
    action: {
      type: String
    },
    annonceId: {
      type: [String, Number]
    }
  },
  computed: {
    newPosition () {
      return this.modelValue.length + 1
    },
    mediaAction () {
      if (this.annonceId) {
        return this.action + '/' + this.newPosition + '/' + this.annonceId
      } else {
        return this.action + '/' + this.newPosition
      }
    }
  },
  methods: {
    makePath (path) {
      if (!path) return '';
      if (path && path.includes('/display-media') === false) {
        return '/display-media' + path
      }
      return path
    },
    mediaOverlayClass (media) {
      return media && media.valide ? '' : 'media-invalid'
    },
    async handleRemove (media) {
      try {
        // Active l'état de suppression
        this.isDeleting[media.id] = true;
        
        let url = 'user/media-delete/' + media.id
        let mediaDelete = await Media.api().delete(url, {
          delete: media.id
        });
        if (mediaDelete.response?.data?.success && mediaDelete.response.data.medias) {
          this.$emit('update:modelValue', mediaDelete.response.data.medias)
        }
      } catch (error) {
        console.error('Error removing media:', error);
      } finally {
        // Désactive l'état de suppression
        this.isDeleting[media.id] = false;
      }
    },
    handleSuccess (response, file, fileList) {
      if (response?.success && response.medias) {
        this.$emit('update:modelValue', response.medias)
      }
    },
    handleError (err, file, fileList) {
      console.error('Upload error:', err);
    },
    handlePreview (media) {
      if (!media?.path) return;
      this.dialogVisible = true
      this.dialogImageUrl = media.path.includes('/display-media') 
        ? media.path 
        : '/display-media' + media.path
      this.dialogMedia = media
    },
    beforeUpload(file) {
      // Vérifier le type de fichier
      const isImage = file.type.startsWith('image/');
      const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      
      if (!isImage || !allowedTypes.includes(file.type)) {
        this.$message.error('Seuls les fichiers JPG, PNG et WebP sont acceptés');
        return false;
      }
      
      // Vérifier la taille (max 10MB)
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('L\'image ne doit pas dépasser 10MB');
        return false;
      }
      
      return true;
    }
  }
}
</script>

<template>
  <div class="lighty-upload-list-img" >
    <div v-for="media in modelValue" class="lighty-upload-list-item-img">
      <el-image 
        style="width: 150px; height: 110px" 
        :src="makePath(media.path)" 
        :fit="'contain'"
        :preview-src-list="[makePath(media.path)]"
      >
        <template #error>
          <div class="image-error">
            <span class="icon-kit error-icon">&#xe9f1</span>
            <span class="error-text">Erreur de chargement</span>
          </div>
        </template>
      </el-image>
      <div class="media-upload-overlay" :class="[mediaOverlayClass(media), {'is-deleting': isDeleting[media.id]}]">
        <span class="el-upload-list__item-actions" >
          <span @click="handlePreview(media)" class="icon-kit" v-html="'&#xea18'"></span>
          <span @click="handleRemove(media)" class="icon-kit" v-html="isDeleting[media.id] ? '&#xe97b' : '&#xe9f1'"></span>
        </span>
        <el-tag
            v-if="media.reason && media.reason.length"
            :type="'danger'"
            class="mx-1"
            effect="plain"
            size="small"
            round
        >
          <span class="dot"></span>
          {{ media.reason }}
        </el-tag>
      </div>
    </div>
  </div>
  <el-upload
      class="upload-media"
      :action="mediaAction"
      drag
      multiple
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handleError"
      accept="image/jpeg,image/png,image/webp"
  >
    <div class="el-upload__text">
      <span class="icon-kit upload-icon circle">&#xe9ff</span>
      <p>
        Glissez / déposez ou <em>cliquez ici pour importer</em> votre image (JPG, PNG ou WebP)
      </p>
    </div>
  </el-upload>
</template>

<style scoped>
.image-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
}

.error-icon {
  font-size: 24px;
  margin-bottom: 8px;
}

.error-text {
  font-size: 12px;
}

.media-upload-overlay .el-upload-list__item-actions {
  color: white;
  cursor: pointer;
}

.media-upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  flex-direction: column;
}

.media-upload-overlay.is-deleting {
  opacity: 1 !important;
  background: rgba(0,0,0,0.7);
}

.media-upload-overlay.is-deleting .icon-kit {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.media-preview-overlay.media-invalid {
  background: rgba(252,34,73,0.5);
}

.media-tag {
  word-break: break-word;
}

.media-upload-overlay.media-invalid {
  background: rgba(252,34,73,0.5);
}

.lighty-upload-list-item-img:hover .media-upload-overlay {
  opacity: 1;
}

.upload-media {
  width: 100%;
}

.upload-icon {
  font-size: 20px;
}

.upload-media .circle {
  display: flex;
  margin-bottom: 12px;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 6px solid var(--gray-50, #F9FAFB);
  background: var(--gray-100, #F2F4F7);
}

.el-upload__text {
  display: flex;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: var(--gray-500, #667085);
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.lighty-upload-list-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  flex-direction: row;
}

.lighty-upload-list-item-img {
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 150px;
  height: 110px;
}
</style>