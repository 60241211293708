<script>
import {asksStatusSlug} from "~/modules/ask/maps/asks-maps";

export default {
  name: "TenantBottomBar",
  emits: ['onClickMenu'],
  beforeRouteUpdate(to, from, next) {
    // Logique à exécuter avant la mise à jour de la route
    if (this.drawer) {
      this.drawer = false
    }

    // N'oublie pas d'appeler next() pour confirmer la navigation
    next();
  },
  data () {
    return {
      drawer: false
    }
  },
  computed: {
    locationLink () {
      return {
        name: 'tenant-asks',
        params: {
          status: asksStatusSlug['all-asks']
        }
      }
    }
  },
  methods: {
    onClickMenu () {
      this.$emit('onClickMenu')
    },
    onClickMenuItem () {
      // this.$emit()
      // this.drawer = false
    }
  }
}
</script>

<template>
  <ul class="horizontally-menu mobile-bottom-bar">
    <li><router-link :to="{name: 'main'}"><span class="icon-kit">&#xe9cc</span><span>Recherche</span></router-link></li>
    <li><router-link :to="locationLink"><span class="icon-kit">&#xe90a</span><span>Locationss</span></router-link></li>
    <li><a :href="path('lighty_share_user_messagerie_default')"><span class="icon-kit">&#xe99a</span><span>Message</span></a></li>
    <li><a href="/" @click.prevent="onClickMenu"><span class="icon-kit">&#xe998</span><span>Menu</span></a></li>
  </ul>
</template>

<style scoped>

</style>