<script>
import UserTicketCard from "~/modules/user/components/UserTicketCard.vue";
import DateRangePicker from 'vue3-daterange-picker'
//you need to import the CSS manually
// import 'vue3-daterange-picker/src/assets/daterangepicker.scss'

function toIsoString(date) {
  let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
}

function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default {
  components: { DateRangePicker, UserTicketCard },
  name: "CartResumeCard",
  emits: ['validate'],
  props: {
    unavailableDays: {
      type: Array,
      default: () => {
        return []
      }
    },
    loadingValidate: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cart: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      dateFormat: 'DD/MM/YYYY'
    }
  },
  methods: {
    dateDisabled (classes, time) {
      let now = new Date()
      let yesterday = addDays(now,-1)
      let beforeYesterday = time < yesterday
      let unavailableDate = false
      let dateKey = this.$moment(time).format('YYYY-MM-DD')
      // if (this.unavailabilityQuantities && this.unavailabilityQuantities[dateKey] && this.unavailabilityQuantities[dateKey][this.ad_id] <= 0) {
      //   unavailableDate = true
      // }
      // if (this.content && this.content.unavailableRange && this.content.unavailableRange.startAt && this.content.unavailableRange.endAt) {
      //   let startAt = new Date(this.content.unavailableRange.startAt)
      //   let endAt = new Date(this.content.unavailableRange.endAt)
      //   if (startAt <= time && time <= endAt) {
      //     unavailableDate = false
      //   }
      // }
      if (!classes.disabled) {
        classes.disabled = beforeYesterday || this.unavailableDays.includes(dateKey)
      }
      return classes
    },
    rangeDate (picker) {
      return this.$moment(picker.startDate).format(this.dateFormat) + ' - ' + this.$moment(picker.endDate).format(this.dateFormat)
    },
    validate () {
      this.$emit('validate')
    }
  },
  computed: {
    testDateRange () {
      return {
          startDate: new Date('2023-01-01'),
          endDate: new Date('2023-01-05'),
      }
    },
    dateRange: {
      get () {
        return {
          // startDate: new Date('2024-01-01'),
          // endDate: new Date('2024-05-01'),
          startDate: this.modelValue && this.modelValue.startAt ? this.modelValue.startAt : '',
          endDate: this.modelValue && this.modelValue.endAt ? this.modelValue.endAt : '',
        }
      },
      set (val) {
        let value = JSON.parse(JSON.stringify(this.modelValue))
        value.startAt = val.startDate
        value.endAt = val.endDate
        this.$emit('update:modelValue', value)
        this.$emit('change')
      }
    },
    pricePerDay () {
      return this.$currencyFormat.format(this.cart.prixNormal / this.cart.nbrJourUtilisation) + ' x ' + this.cart.nbrJourUtilisation + ' jour(s)'
    },
    pricePerDayTotal () {
      return this.$currencyFormat.format(this.cart.prixNormal)
    },
    hasDegression () {
      return this.cart.prixNormal - this.cart.prixHT > 0
    },
    degression () {
      return '- ' + this.$currencyFormat.format(this.cart.prixNormal - this.cart.prixHT)
    },
    hasService () {
      return this.cart.serviceTotalHt > 0
    },
    servicesTotal () {
      return this.$currencyFormat.format(this.cart.serviceTotalHt)
    },
    assuranceTotal () {
      return this.$currencyFormat.format(this.cart.assuranceHt)
    },
    feesHt () {
      return this.$currencyFormat.format(this.cart.fraisServiceHT)
    },
    prixTotalHt () {
      return this.$currencyFormat.format(this.cart.prixTotalHt)
    },
    vat () {
      return this.$currencyFormat.format(this.cart.prixTotalTtc - this.cart.prixTotalHt)
    },
    prixTotalTtc () {
      return this.$currencyFormat.format(this.cart.prixTotalTtc)
    },
    caution () {
      return this.$currencyFormat.format(this.cart.caution)
    },
    valeurTotalMateriel () {
      return this.$currencyFormat.format(this.cart.valeurTotalMateriel)
    },
    localeData () {
      return {
        cancelLabel: '',
        applyLabel: this.$t('valid-cart.cart-data.validate'),
        firstDay: 1,
        "daysOfWeek": [
          this.$t('valid-cart.cart-data.date.daysWeek.1'),
          this.$t('valid-cart.cart-data.date.daysWeek.2'),
          this.$t('valid-cart.cart-data.date.daysWeek.3'),
          this.$t('valid-cart.cart-data.date.daysWeek.4'),
          this.$t('valid-cart.cart-data.date.daysWeek.5'),
          this.$t('valid-cart.cart-data.date.daysWeek.6'),
          this.$t('valid-cart.cart-data.date.daysWeek.7')
        ],
        "monthNames": [
          this.$t('valid-cart.cart-data.date.monthNames.1'),
          this.$t('valid-cart.cart-data.date.monthNames.2'),
          this.$t('valid-cart.cart-data.date.monthNames.3'),
          this.$t('valid-cart.cart-data.date.monthNames.4'),
          this.$t('valid-cart.cart-data.date.monthNames.5'),
          this.$t('valid-cart.cart-data.date.monthNames.6'),
          this.$t('valid-cart.cart-data.date.monthNames.7'),
          this.$t('valid-cart.cart-data.date.monthNames.8'),
          this.$t('valid-cart.cart-data.date.monthNames.9'),
          this.$t('valid-cart.cart-data.date.monthNames.10'),
          this.$t('valid-cart.cart-data.date.monthNames.11'),
          this.$t('valid-cart.cart-data.date.monthNames.12')
        ],
      }
    }
  }
}
</script>

<template>
  <div v-if="cart" class="cart-resume-card">
    <user-ticket-card v-if="cart.proprietaire_annonce" :user="cart.proprietaire_annonce" class="large"></user-ticket-card>
    <hr>

    <date-range-picker
        v-if="this.modelValue && this.modelValue.startAt && this.modelValue.endAt"
        v-model="dateRange"
        :dateRange="dateRange"
        :dateFormat="dateDisabled"
        :ranges="false"
        :locale-data="localeData"
        :opens="'left'"
        :append-to-body="false"
    >
      <template #input="picker" style="width: 100%;">
        {{ rangeDate(picker) }}
      </template>
      <template #footer="data">
        <div class="daterangepicker-footer">
          <span class='drp-custom-text'>{{ $t('valid-cart.cart-data.custom-text') }}</span>
          <button @click="data.clickApply" class="applyBtn btn btn-sm btn-primary" type="button">Valider</button>

        </div>
      </template>
    </date-range-picker>
    <div class="price-resume-panel">
      <div class="panel">
        <span>{{pricePerDay}}</span>
        <span>{{pricePerDayTotal}}</span>
      </div>
      <div class="panel" v-if="hasDegression" >
        <span>{{$t('valid-cart.cart-data.degress')}}
          <el-tooltip placement="top" popper-class="small-popper">
            <template #content> {{ $t('valid-cart.cart-data.degress-infos') }}</template>
            <span class="icomoon">&#xe983</span>
          </el-tooltip>
        </span>
        <span style="color: #28933A;">{{ degression }}</span>
      </div>
      <div v-if="hasService" class="panel">
        <span>Services</span>
        <span>{{ servicesTotal }}</span>
      </div>
      <div class="panel">
        <span>Assurance</span>
        <span>{{ assuranceTotal }}</span>
      </div>
      <div class="panel">
        <span>{{ $t('valid-cart.cart-data.serviceHT')}}
          <el-tooltip placement="top" popper-class="small-popper">
            <template #content> {{ $t('valid-cart.cart-data.serviceHT-infos') }}</template>
            <span class="icomoon">&#xe983</span>
          </el-tooltip>
        </span>
        <span>{{ feesHt }}</span>
      </div>
      <hr>
      <div class="panel">
        <span>{{ $t('valid-cart.cart-data.totalHT') }}</span>
        <span>{{ prixTotalHt }}</span>
      </div>
      <div class="panel">
        <span>{{ $t('valid-cart.cart-data.TVA')}}</span>
        <span>{{ vat }}</span>
      </div>
      <div class="panel">
        <span>{{ $t('valid-cart.cart-data.totalTTC')}}</span>
        <span>{{ prixTotalTtc }}</span>
      </div>
    </div>
    <el-button @click="validate" :loading="loadingValidate" size="large" class="btn btn-short btn-primary mt-4 save-cart only-desktop">{{ $t('valid-cart.cart-data.validate-btn') }}</el-button>
    <p style="text-align:center;font-weight:700;" class="insurance-included">
      <img
        :src="asset('img/new/product-single/mdi_verified_user.png')">
      &nbsp; {{ $t('valid-cart.cart-data.insurance') }}
    </p>
  </div>
  <div class="depot-section">
    <h4>{{ $t('valid-cart.cart-data.caution') }}
      <span id="caution">
          {{ caution }}
      </span>
      <el-tooltip placement="top" popper-class="small-popper">
        <template #content> {{ $t('valid-cart.cart-data.caution-infos') }}</template>
        <a href="#" class="icomoon tooltip-icon">&#xe983</a>
      </el-tooltip>
    </h4>
    <h4>{{ $t('valid-cart.cart-data.replacement') }}
      <span id="valeurRemplacement">
							{{ valeurTotalMateriel }}
      </span>
      <el-tooltip placement="top" popper-class="small-popper">
        <template #content> {{ $t('valid-cart.cart-data.replacement') }}</template>
        <a href="#" class="icomoon tooltip-icon">&#xe983</a>
      </el-tooltip>
    </h4>
  </div>
</template>

<style>
 .el-popper.small-popper {
   width: 200px;
   text-align: center;
 }
</style>