<template>
    <el-menu
        :default-openeds="menuActive"
        :default-active="itemOpenIndex"
        class="el-menu-vertical-demo"
        :unique-opened="true"
    >
      <menu-item v-for="item in routesItems" :item="item" :link-class="'espace-loueur'">
      </menu-item>
    </el-menu>
    <div class="bottom-menu">
      <el-menu
          :unique-opened="true"
      >
        <menu-item v-for="item in userRoutesItems" :item="item" >
        </menu-item>
      </el-menu>
    </div>
</template>
<script>
  // import 'element-plus/theme-chalk/display.css'
  import MenuItem from "./MenuItem.vue"
  import Auth from "../modules/auth/services/Auth";
  import { asksStatusSlug, reverseMap } from "~/modules/ask/maps/asks-maps.js";

  Array.prototype.insert = function ( index, ...items ) {
    this.splice( index, 0, ...items );
  };

  export default {
    name: "LeftBar",
    emits: ['item-click'],
    props: {
      aside: {
        type: Boolean,
        default: true
      },
      header: {
        type: Boolean,
        default: true
      },
      menuClass: {
        type: String,
        default: ''
      },
      containerClass: {
        type: String,
        default: ''
      }
    },
    components: {
      MenuItem
    },
    data () {
      return {
        bottomMenu: [
          // {
          //   icon: '&#xe957',
          //   label: 'Revenus',
          //   to: { name: 'revenus' }
          // },
          {
            icon: '&#xe989',
            label: 'Support',
          },
          {
            icon: '&#xe991',
            label: 'Mode locataire',
            class: 'grey',
            to: { href: '/'}
          }
        ],
        itemsMenu: [
          {
            icon: '&#xe988;',
            label: 'Dashboard',
            to: {name: 'dashboard'}
          },
          {
            icon: '&#xea16',
            label: 'Reservations',
            submenu: [
              {
                label: this.$t('owner-rental.tabs.today'),
                to: {name: 'today-asks'},
                isActive: this.generateAsksIsActiveItem('today-asks')
              },
              {
                label: this.$t('owner-rental.tabs.waiting'),
                to: { name: 'waiting-asks'},
                isActive: this.generateAsksIsActiveItem('waiting-asks')
              },
              {
                label: this.$t('owner-rental.tabs.departures'),
                to: {
                  name: 'departures-asks'
                },
                isActive: this.generateAsksIsActiveItem('departures-asks')
              },
              {
                label: this.$t('owner-rental.tabs.arrivals'),
                to: {
                  name: 'arrivals-asks'
                },
                isActive: this.generateAsksIsActiveItem('arrivals-asks')
              },
              {
                label: this.$t('owner-rental.tabs.cancel'),
                to: {
                  name: 'cancel-asks'
                },
                isActive: this.generateAsksIsActiveItem('cancel-asks')
              },
              {
                label: this.$t('owner-rental.tabs.all'),
                to: { name: 'all-asks'},
                isActive: this.generateAsksIsActiveItem('all-asks')
              },
            ]
            // to: {name: 'reservations'}
          },
          {
            icon: '&#xe928;',
            label: 'Produits',
            isActive (route) {
              return route.path.includes('shop/produits') || route.path.includes('shop/produit')
            },
            to: { name: 'annonces' }
          },
          // {
          //   label: 'Paramètres',
          //   submenu: [
          //     {
          //       label: 'Profil'
          //     },
          //     {
          //       label: 'Compte'
          //     },
          //     {
          //       label: 'Réseau sociaux'
          //     },
          //     {
          //       label: 'Certification'
          //     },
          //     {
          //       label: 'Sécurité'
          //     },
          //     {
          //       label: 'Paiement'
          //     }
          //   ]
          // },
          {
            icon: '&#xe980',
            label: 'Boutique',
            to: { name: 'disponibilite'},
            isActive (route) {
              return route.path.includes('shop/disponibilite') || route.path.includes('shop/tarification') || route.path.includes('shop/services') || route.path.includes('shop/offres') || route.path.includes('shop/validation-banque') || route.path.includes('shop/info-bancaire') || route.path.includes('shop/conditions-annulation')
            },
            // submenu: [
            //   {
            //     label: 'Disponibilité',
            //     to: { name: 'disponibilite'}
            //   },
            //   {
            //     label: 'Tarification',
            //     to: { name: 'tarification'}
            //   },
            //   {
            //     label: 'Services',
            //     to: { name: 'services'}
            //   },
            //   {
            //     label: 'Offres',
            //     to: { name: 'offres'}
            //   },
            //   {
            //     label: 'Info bancaires',
            //     to: { name: 'BankInfo'}
            //   },
            //   {
            //     label: 'Validation banque',
            //     to: { name: 'BankValidation'}
            //   },
            //   {
            //     label: "Conditions d'annulation",
            //     to: { name: 'CancelConditions'}
            //   }
            // ]
          },
          {
            icon: '&#xe965;',
            label: 'Documents',
            isActive (route) {
              return route.path.includes('shop/documents')
            },
            to: {name: 'documents'}
          },

          // {
          //   icon: '&#xe957',
          //   label: 'Revenus',
          //   to: { name: 'revenus' }
          // },
          // {
          //   icon: '&#xe989',
          //   label: 'Support'
          // }
        ]
      }
    },
    methods: {
      generateAsksIsActiveItem (status) {
        return function (route) {
          if (route.name === status) {
            return true
          } else if (route.params && route.params.status) {
            const reversedSlug = reverseMap(asksStatusSlug)
            return reversedSlug[route.params.status] === status
          }
        }
      },
      openBeacon () {
        Beacon('navigate', '/ask/')
        Beacon('open')
      },
      formatMenuItem (item, key) {
        key = key + 1
        let route = false
        let submenu = []
        let classes = ''
        if (item.class) {
          classes = item.class
        }
        let tagElement = 'el-menu-item'
        if (typeof item.to !== 'undefined') {
          route = this.$router.resolve(item.to)
        }
        if (Array.isArray(item.submenu)) {
          tagElement = 'el-sub-menu'
          let submenuKey = key + '-'
          submenu = item.submenu.map((item, subKey) => {
            const itemMenu = this.formatMenuItem(item, subKey)
            itemMenu['key'] = submenuKey+itemMenu['key']
            return {
              ...itemMenu
            }
          })
        }
        return {
          ...item,
          key: '' + key,
          route,
          submenu,
          class: classes
        }
      },
      findRoute (acc, item) {
        if (typeof item.isActive === 'function') {
          return item.isActive(this.$route) ? item.key : acc
        } else if (item.route && item.route.name && item.route.name === this.$route.name) {
          return item.key
        }
        if (!acc && Array.isArray(item.submenu)) {
          return item.submenu.reduce(this.findRoute, false)
        }
        return acc
      }
    },
    computed: {
      defaultOpenIndex () {
       // return this.itemOpenIndex.split('-')[0]
      },
      menuActive () {
        let item = []
        if (this.itemOpenIndex && this.itemOpenIndex.split('-').length) {
          item.push(this.itemOpenIndex.split('-')[0])
        }
        return item
      },
      submenuActive () {
        return [this.itemOpenIndex.split('-')[1]]
      },
      itemOpenIndex () {
        const routesIndex = this.routesItems.reduce(this.findRoute, false)
        console.log('itemOpenIndex')
        console.log(routesIndex)
        return routesIndex ? routesIndex : ''
      },
      auth () {
        return Auth.query().first()
      },
      userRoutesItems () {
        const items = [
            ...this.bottomMenu
        ]
        return items.map(this.formatMenuItem)
      },
      routesItems () {
        const items = [
            ...this.itemsMenu
        ]
        if (this.auth && Array.isArray(this.auth.rolesList) && this.auth.rolesList.includes('ROLE_ADMIN')) {
          items.push({
            icon: '&#xe950;',
            label: 'Admin',
            to: { name: 'admin-statistics' }
          })
        }
        return items.map(this.formatMenuItem)
      }
    }
  }
</script>
<style lang="scss">
  body.no-margin {
    margin-top: 0px;
  }
  .bottom-menu {
    border-top: solid 1px #EAECF0;
    padding: 24px 20px;
  }
  .grey {
    color: #CCC !important;
    & .router-link-active {
      color: #CCC !important;
    }
    & .router-link-exact-active {
      color: #CCC !important;
      &:hover {
        color: var(--el-color-primary) !important;
      }
    }
    &:hover {
      color: var(--el-color-primary) !important;
    }
  }
  .inner_left_drawer {
    /*top: 0px;*/
    /*position: absolute;*/
    /*background: red;*/
    /*width: 216px;*/
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px -3px rgba(8, 0, 37, 0.10);
    display: grid;
    font-family: "Inter", sans-serif;
    grid-template-rows: auto 1fr auto;
    width: 250px;
    height: 100vh; /* Ceci assure que la grille remplit la hauteur de la fenêtre de visualisation. Vous pouvez ajuster cette valeur selon vos besoins. */
    gap: 10px;
  }
  .el-menu {
    border-right: 0px;
    border-right: none;
  }
  .top-logo {
    height: 31px;
    margin: 24px 32px 24px 24px;
    margin-bottom: 5px;
    width: auto;
  }
</style>
