<script>
import CustomTabs from "~/components/CustomTabs.vue";
import {asksStatusTitles, asksStatusSlug, reverseMap} from "~/modules/ask/maps/asks-maps.js";

export default {
  name: "TenantAsksLayout",
  components: {CustomTabs},
  data () {
    return {
      tabs: [
        {
          label: this.$t('tenant-asks.tabs.waiting'),
          to: { name: 'tenant-asks', params: {status: asksStatusSlug['waiting-asks']}}
        },
        {
          label: this.$t('tenant-asks.tabs.accepted'),
          to: { name: 'tenant-asks', params: {status: asksStatusSlug['accepted-asks']}}
        },
        {
          label: this.$t('tenant-asks.tabs.finished'),
          to: { name: 'tenant-asks', params: {status: asksStatusSlug['finished-asks']}}
        },
        {
          label: this.$t('tenant-asks.tabs.canceled'),
          to: { name: 'tenant-asks', params: {status: asksStatusSlug['cancel-asks']}}
        },
        {
          label: this.$t('tenant-asks.tabs.all'),
          to: { name: 'tenant-asks', params: {status: asksStatusSlug['all-asks']}}
        },
        // {
        //   label: this.$t('owner-rental.tabs.1'),
        //   to: { name: 'reservations-annulees'}
        // }

      ]
    }
  },
}
</script>

<template>
  <div class="container page-template-container">
    <div class="reservation-header">
      <h3>Locations</h3>
      <h4>Espace locataire <i class="fas fa-circle"></i>
        <a href="/public/13886/show" class="profile-link ml-2"><span>Voir mon profil</span></a>
      </h4>
    </div>
    <CustomTabs :tabs="tabs"></CustomTabs>
    <router-view></router-view>
  </div>
</template>

<style scoped>

</style>