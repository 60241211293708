<template>
  <div ref="mainScroll" id="main-scroll">
    <div class="main-scroll-container">
      <AuthMenuSmall :notifications="notifications" ></AuthMenuSmall>
      <router-view></router-view>
    </div>
<!--    <el-main style="min-height: 100vh;">-->
<!--    </el-main>-->
  </div>
</template>

<script>
import AuthMenuSmall from "../../modules/auth/components/AuthMenuSmall.vue";
import {loadNewNotifications} from "~/services/notifications";


export default {
  components: {
    AuthMenuSmall
  },
  data () {
    return {
      intervalNotifications: null,
      notifications: {
        newRentals: 0,
        newMessages: 0,
        newLocations: 0,
        newPanierSessions: 0
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val) {
        if (val) {
          this.scrollToTop()
          // this.$refs.mainScroll.$el.scrollTop = 0
        }
      }
    }
  },
  methods: {
    scrollToTop () {
      if (this.$refs.mainScroll) {
        this.$refs.mainScroll.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    async fetchNotification () {
      try {
        const notification = await loadNewNotifications()
        if (notification.data) {
          this.notifications = notification.data
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted () {
    this.fetchNotification()
    this.intervalNotifications = setInterval(() => {
      this.fetchNotification()

      // this.notifications = {
      //   newRentals: 0,
      //   newMessages: 0,
      //   newLocations: 0,
      //   newPanierSessions: 0
      // }
    }, 30000)
  },
  beforeUnmount() {
    // Nettoyer l'intervalle pour éviter les fuites de mémoire
    if (this.intervalNotifications) {
      clearInterval(this.intervalNotifications);
    }
  },
  name: "ParamatersView"
}
</script>

<style scoped>

</style>