<template>
  <div class="common-layout" id="website">
    <el-drawer v-model="drawer" :direction="'ltr'" :size="'90%'" :show-close="false">
      <template #header>
        <div class="drawer-header">
          <img :src="asset('img/new/logo-dark.png')" alt="logo-espace-loueur" class="top-logo">
          <span class="close-icon" @click="drawer = false"><span class="icon-kit">&#xe933</span></span>
        </div>
      </template>
      <div class="mobile-left-bar">
        <router-view name="LeftBar" @itemClick="onItemClick"></router-view>
      </div>
    </el-drawer>
    <router-view name="TopBar"></router-view>
    <router-view name="Page"></router-view>
    <router-view name="Footer"></router-view>
    <router-view name="BottomBar" @onClickMenu="onClickMenu"></router-view>

  </div>
</template>

<script>
export default {
  name: "BaseDefaultLayout",
  data() {
    return {
      drawer: false
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Logique à exécuter avant la mise à jour de la route
    if (this.drawer) {
      this.drawer = false
    }

    // N'oublie pas d'appeler next() pour confirmer la navigation
    next();
  },
  methods: {
    onItemClick () {
      console.log('item click')
    },
    onClickMenu () {
      console.log('click menu')
      this.drawer = !this.drawer
    }
  }
}
</script>

<style>
  #website {
    margin-top: 70px;
  }
</style>