export const asksStatusTitles = {
  'all-asks': 'Toutes',
  'today-asks': 'Aujourd\'hui',
  'waiting-asks': 'En attentes',
  'departures-asks': 'Départs',
  'arrivals-asks': 'Retours',
  'cancel-asks': 'Annulées',
  'finished-asks': 'Précédentes',
  'accepted-asks': 'Validées',
}

export const asksStatusSlug = {
  'all-asks': 'toutes',
  'today-asks': 'aujourd-hui',
  'waiting-asks': 'en-attentes',
  'departures-asks': 'departs',
  'arrivals-asks': 'arrivees',
  'cancel-asks': 'annulations',
  'finished-asks': 'precedentes',
  'accepted-asks': 'validees',
  // 'cancel-asks': 'annulations',
}

export function reverseMap (map) {
  return Object.keys(map).reduce((acc, key) => {
    acc[asksStatusSlug[key]] = key
    return acc
  }, {})
}