<script>
import Auth from "~/modules/auth/services/Auth";
import Rental from "~/modules/ask/services/Rental";
import LoadContent from "~/mixins/loadContent";
import { asksStatusSlug, reverseMap} from "~/modules/ask/maps/asks-maps.js";
import AsksTable from "~/modules/ask/components/AsksTable.vue";

export default {
  name: "TenantAsksList",
  components: {AsksTable},
  mixins: [LoadContent],
  computed: {
    cols () {
      return ['proprietaire', 'status', 'titre_location', 'list', 'departUnix', 'finUnix', 'ownerRevenuHT', 'action']
    },
    status () {
      return this.$route.params.status
    },
    formattedStatus () {
      const reverseSlug = reverseMap(asksStatusSlug)
      return reverseSlug[this.status]
    },
    auth () {
      return Auth.query().first()
    },
    Model () {
      return Rental
    },
    fetchUrl () {
      return '/api/locataire/reservations/' + this.formattedStatus
    }
  },
  data () {
    return {
    }
  },
  methods: {
    applySearchQuery (query) {
      if (this.search.length > 0) {
        query.whereHas('locataire', (query) => {
          query.where((locataire) => {
            return (locataire.fullName && locataire.fullName.toLowerCase().includes(this.search.toLowerCase()))
          })
        }).orWhere((ask) => {
          let find = false
          return find || (ask.titre_location && ask.titre_location.includes(this.search)) || ask.id === parseInt(this.search)
        })
      }
    },
    preQuery (query) {
      query.with(['proprietaire.ImageProfil', 'proprietaire.infoSociete'])
      let now = new Date();
      now.setHours(0,0,0,0);
      query.where((ask) => {
        return parseInt(ask.tenantId) === parseInt(this.auth.id)
      })
      if (this.formattedStatus === 'waiting-asks') {
        query.where((ask) => {
          return (ask.statutLocataire < 3 && ask.statutProprietaire < 3)
              //&& ask.departUnix >= now.getTime() / 1000 && !ask.canceled)
        })
      } else if (this.formattedStatus === 'accepted-asks') {
        query.where((ask) => {
          return ask.statutLocataire == 3 && ask.statutProprietaire == 3 && !ask.canceled
              //(ask.departUnix >= now.getTime() / 1000 && !ask.canceled)
        })
      } else if (this.formattedStatus === 'finished-asks') {
        query.where((ask) => {
          return ask.statutLocataire > 3 && ask.statutProprietaire > 3 && !ask.canceled
          // return (ask.statutLocataire === 3 && ask.statutProprietaire === 3 && ask.departUnix >= now.getTime() / 1000 && !ask.canceled)
        })
      } else if (this.formattedStatus === 'all-asks') {
        // query.where((ask) => {
        //   return (ask.statutLocataire >= 3 && ask.statutProprietaire >= 3) && ask.departUnix < now.getTime() / 1000 && ask.finUnix >= now.getTime() / 1000 && !ask.canceled
        // })
      } else if (this.formattedStatus === 'cancel-asks') {
        query.where((ask) => {
          return ask.canceled
        })

      }
    },
    goToResearch () {
      this.$router.push({name: 'main'})
    },
    onClickRow (row) {
      this.$router.push({
        name: 'tenant-ask',
        params: {
          id: row.id
        }
      })
      console.log('click row')
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
}
</script>

<template>
  <AsksTable :asks="modelList" @rowClick="onClickRow" :router-link="false" :columns-filter="cols" @sort-change="sortChange">
    <template #empty>
      <div class="empty-background">
        <div v-if="isLoadingContent" class="loading-content">
          <div class="cv-spinner">
            <span class="spinner"></span>
          </div>
          <div class="empty-background-content-title">
            {{ $t('shop.products.histories.loading')}}
          </div>
        </div>
        <div v-else class="empty-content">
          <div>
            <div class="non-clickable-button icomoon">
              &#xea16
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.products.histories.empty.title')}}
            </div>
            <div class="empty-background-content-description">
              {{ $t('shop.products.histories.empty.description')}}
            </div>
          </div>
          <div class="empty-background-content-button">
            <el-button @click="goToResearch()" size="large" type="primary"><span class="icomoon" style="margin-right: 8px">&#xe9d1</span>   {{ $t('shop.products.histories.empty.button')}}</el-button>
          </div>
        </div>
      </div>
    </template>
  </AsksTable>
  <div class="pagination-container" v-if="paginationQuery.countPage > 1">
    <el-pagination v-if="paginationQuery.count" :current-page="currentPage" background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
  </div>
</template>

<style scoped>

</style>